<template>
  <div>
    <md-toolbar class="md-primary mb-2" md-elevation="1">
        <h3 class="md-title" style="flex: 1">Add ons</h3>
        
        <md-button class="md-primary md-raised" @click="add">New add on</md-button>
        
    </md-toolbar>
    <md-progress-bar md-mode="indeterminate" v-if="isBusy"></md-progress-bar>
    
  
    <div class="md-content md-table md-theme-default">
      <div class="md-content md-table-content md-scrollbar md-theme-default">
          <table>
              
                <tr class="md-table-row">
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Name
                    </div>
                    
                  </th>
                 
                  <th class="md-table-head">
                    <div class="md-table-cell-container">
                      Order
                    </div>
                    
                  </th>
                </tr>
              
              <draggable v-model="paginated" tag="tbody" @change="listUpdated" class="table-content">
                <tr v-for="item in paginated" :key="item.id" class="md-table-row" @click="rowClicked(item)">
                  <td class="md-table-cell">
                      <div class="md-table-cell-container">{{ item.name }}
                      </div>
                  </td>
                 
                  <td class="md-table-cell">
                      <div class="md-table-cell-container">
                        <md-icon class="grab-cursor">drag_indicator</md-icon>
                      </div>
                  </td>
                </tr>
              </draggable>
          </table>
          <div class="md-layout pl-3 pr-3 md-alignment-center-left">
            <div class="md-layout-item">
              <vue-ads-pagination
                :total-items="total"
                :max-visible-pages="5"
                :page="activePage"
                :items-per-page="rowsPerPage"
                :loading="isBusy"
                @page-change="pageChange"
                @range-change="rangeChange"
            >
                <template slot-scope="props">
                    <div class="vue-ads-pr-2 vue-ads-leading-loose">
                        Showing Items {{ props.start }} to {{ props.end }} of {{ props.total }}
                    </div>
                </template>
                
                <template
                    slot="buttons"
                    slot-scope="props"
                >
                
                    <vue-ads-page-button
                        v-for="(button, key) in props.buttons"
                        :key="key"
                        v-bind="button"
                        @page-change="activePage = button.page"
                    />
                </template>
              </vue-ads-pagination>
            </div>
            <div class="md-layout-item md-size-10">
              <md-field class="mb-0">
                  <label>Rows</label>
                  <md-select v-model="rowsPerPage" md-dense>
                    <md-option :value="option" v-for="option in pageOptions" v-bind:key="option">{{option}}</md-option>
                  </md-select>
              </md-field>
            </div>
          </div>
         
      </div>
    </div>
    <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
        <span>{{snackMessage}}</span>
        <md-button class="md-primary" @click="showSnackbar = false">OK</md-button>
    </md-snackbar>
    <!--CDataTable
            hover
            striped
            :items="addons"
            :fields="fields"
            :items-per-page="5"
            clickable-rows
            :active-page="activePage"
            @row-clicked="rowClicked"
            :pagination="{ doubleArrows: false, align: 'center'}"
            @page-change="pageChange"
            
          >
     
    </CDataTable-->
   
     
  </div>
</template>

<script>
import firebase from '../../Firebase'
import CTableWrapper from '../base/Table'
import draggable from 'vuedraggable'
import 'vue-ads-pagination/dist/vue-ads-pagination.css';
 
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
export default {
  name: 'Addons',
  components: { 
    CTableWrapper, 
    draggable , 
    VueAdsPagination,
    VueAdsPageButton, 
  },
  data() {
    return {
      showSnackbar: false,
      position: 'center',
      duration: 7000,
      isInfinity: false,
      snackMessage: '',
      isBusy: true,
      addons: [],
      //ref: firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
      //items: usersData,
      fields: [
        {key: 'name', label: 'Name'},
       
       
      ],
      paginated: [],
      activePage: 0,
      rowsPerPage: 10,
      total:0,
      pageOptions: [5,10,15,20,25,30],
      partialStart: null,
      partialEnd: null
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created () {
    console.log(this.$store);
    //console.log(this.$firebase.getCurrentUser());
  
    firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').orderBy("order").onSnapshot((querySnapshot) => {
      this.addons = [];
      querySnapshot.forEach((doc) => {

        let dataToSave = doc.data();
        dataToSave.id = doc.id ;
        this.addons.push(dataToSave);

      });
      console.log(this.addons);
      this.total = this.addons.length;
      this.isBusy = false
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        console.log('Add ons watch')
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    pageChange (page) {
        console.log('Page change')
        this.page = page;
    },
    
    rangeChange (start, end) {
        console.log('Range change')
        this.paginated = this.addons.slice(start,end)
        this.partialStart = start;
        this.partialEnd = end;


    },
    listUpdated(ev){
      // console.log(ev);
      // console.log(ev.moved.element.type);
      // console.log('List updated');
      for (let i = this.partialStart  ; i < this.paginated.length + this.partialStart ; i++) {
          this.addons.splice(i,1,this.paginated[i - this.partialStart]);
        
      }
      
      this.updateOrder();
    },
    rowClicked (item, index) {
      console.log(item);
      
      this.$router.push({
        //path: `add-ons/${item.id}`
        path: `/app/add-ons/${item.name}/${item.id}`,
      })
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    add(){
      this.$router.push({path: `/app/add-ons/new`})
    },
    updateOrder() {
      this.isBusy = true;
      let th = this;
      for (let index = 0; index < this.addons.length; index++) {
          this.addons[index].order = index + 1;
          firebase.firestore().collection('users').doc(this.user.data.uid).collection('add-ons').doc(this.addons[index].id).update({
            order: index + 1
          })
          .then(function(){
              th.snackMessage = 'Add ons order updated';
              th.isBusy = false;
              th.showSnackbar = true;
          }).catch(function(error) {
             console.log("Error getting document:", error);
             th.isBusy = false;
          });
      }
      
    },
  }
}
</script>